import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock } from "@fortawesome/free-regular-svg-icons";

import Layout from "../components/layout";
import Tag from "../components/blog/tag";
import ShareWidget from "../components/blog/share-widget";
import COLORS from "../constants/colors";

const PostWrapper = styled.div`
  display: flex;
  font-family: Montserrat, Roboto, "Helvetica Neue";
  height: 100%;
  overflow: scroll;
  padding: 5em;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 1rem 2.5rem 2.5rem;
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: 1rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const PostMeta = styled.div`
  flex: 1;
  margin-right: 15px;

  @media (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-right: 0;
  }
`;

const PostContent = styled.div`
  flex: 4;
  margin-left: 15px;
  line-height: 1.75rem;
`;

const PostTitle = styled.h1`
  margin-top: 1rem;
`;

const MetaWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MetaText = styled.p`
  margin-left: 0.5rem;
  font-size: 0.9rem;

  @media (max-width: 1024px) {
    margin-top: 1rem;
  }
`;

const Separator = styled.hr`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    border: 1px solid ${COLORS.GRAY.DARK};
    opacity: 0.3;
    margin-top: 1rem;
  }
`;

const BlogPost = props => {
  const post = props.data.markdownRemark;
  const { date, image, tags, title } = post.frontmatter;

  return (
    <Layout>
      <PostWrapper>
        <Separator />
        <PostMeta>
          <ImageWrapper>
            <Img fluid={image.childImageSharp.fluid} />
          </ImageWrapper>
          <MetaWrapper>
            <FontAwesomeIcon
              icon={faCalendarAlt}
              color={COLORS.GRAY.DARK}
              size="1x"
            />
            <MetaText>{date}</MetaText>
          </MetaWrapper>
          <MetaWrapper>
            <FontAwesomeIcon
              icon={faClock}
              color={COLORS.GRAY.DARK}
              size="1x"
            />
            <MetaText>{post.timeToRead} min</MetaText>
          </MetaWrapper>
          <MetaWrapper>
            {tags.map(tag => (
              <Tag key={tag} type={tag} />
            ))}
          </MetaWrapper>
          <ShareWidget mode="compact" title={title} />
        </PostMeta>
        <Separator />
        <PostContent>
          <PostTitle>{title}</PostTitle>
          <div
            className="post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </PostContent>
      </PostWrapper>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      timeToRead
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        image {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 786) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
